
import { defineComponent, toRefs, computed, reactive, Ref, ref, nextTick } from 'vue'
import listLayout from '@/components/listLayout/listLayout.vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import yTable from '@/components/yTable/index.vue'
import editButton from '@/components/editButton/index.vue'
import FormItem from '@/components/formItem/index.vue'
import { FormItemListType } from '@/types/comm'
import Data from '@/model/common/Data'
import modalForm from '@/components/modalForm/index.vue'
import { deleteOneModal } from '@/utils/deleteModal'
import { DeptClass, ReqDeptClass } from '@/model/authorityManagement/dept'
import { AddDept, DeleteDept, PageDept, UpdateDept } from '@/axios/api/dept'
import { debounce } from 'lodash-es'
import router from '@/router'
import { getSuperiorDept, getSuperiorDeptMember } from './common'
import { PersonnelClass } from '@/model/authorityManagement/personnel'
import { ColumnsTypeData } from '@/components/Drawer/Drawer'
import Drawer from '@/components/Drawer/Drawer.vue'
export default defineComponent({
  components: {
    listLayout,
    yTable,
    FormItem,
    editButton,
    modalForm,
    Drawer,
  },
  setup() {
    onBeforeRouteUpdate((to, from) => {
      console.log(from.name, pageData.selectFrom)
      store.commit('setRegForm', [from.name, pageData.selectFrom])
    })
    /**
     * @description: 获取筛选后可展示表头列表
     * @param { Array<ColumnsTypeData> } data 当前所有表头列表
     * @return {*}
     */
    const updateColumns = (value: Array<ColumnsTypeData>) => {
      pageData.loading = true
      pageData.columns = value
      nextTick(() => {
        pageData.loading = false
      })
    }
    //获取路由
    let route = useRoute()
    //获取store
    const store = useStore()
    const buttonObj = store.getters.getButtonObj

    const pageData = reactive({
      isShowDrawer: false, //列设置弹框展示控制
      selectFrom: new ReqDeptClass(),
      dataSource: [] as DeptClass[],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 200,
          key: 'index',
          resizable: true,
          customRender: ({ index }: { index: number }) => index + 1,
        },
        {
          title: '部门名称',
          dataIndex: 'deptName',
          width: 200,
          key: 'deptName',
          resizable: true,
        },
        {
          title: '上级部门',
          dataIndex: 'superiorDeptName',
          width: 200,
          key: 'superiorDeptName',
          resizable: true,
        },
        {
          title: '部门管理员',
          dataIndex: 'adminName',
          width: 200,
          key: 'adminName',
          resizable: true,
        },
        {
          title: '管理员工号',
          dataIndex: 'adminCode',
          width: 200,
          key: 'adminCode',
          resizable: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          width: 200,
          fixed: 'right',
          resizable: true,
        },
      ] as ColumnsTypeData[],
      selectedRowKeys: [] as string[],
      loading: false,
      formItemList: [
        {
          type: 'subInput',
          prop: 'deptName',
          placeholder: '请输入部门名称',
          label: '部门名称',
          maxlength: 20,
        },
        {
          type: 'subInput',
          prop: 'adminName',
          placeholder: '请选择部门管理员',
          label: '部门管理员:',
          optionList: [
            { label: '关注', value: 1 },
            { label: '未关注', value: 0 },
          ],
        },
      ] as FormItemListType<DeptClass>,
      modalData: {
        title: '新增',
        form: {} as DeptClass,
        formItemList: [
          {
            type: 'subInput',
            prop: 'deptName',
            placeholder: '请输入部门名称',
            label: '部门名称',
          },
          {
            type: 'slot',
            prop: 'superiorDept',
            placeholder: '请输入上级部门',
            label: '上级部门',
            optionList: [],
          },
        ] as FormItemListType<Data>,
        rules: {
          deptName: [{ required: true, message: '部门名称不可为空', trigger: 'blur' }],
          superiorDept: [{ required: false, message: '上级部门不可为空', trigger: 'blur' }],
        },
        visible: false,
        labelCol: { style: { width: '100px' } },
        width: '25%',
        ok: (formRef?: Ref) => {
          formRef?.value
            .validate()
            .then(() => {
              let api = pageData.modalData.title === '新增' ? AddDept : UpdateDept
              api(pageData.modalData.form)
                .then(() => {
                  pageData.modalData.visible = false
                  initeList()
                  message.success('成功')
                })
                .catch((err) => {
                  message.error(err)
                })
            })
            .catch((err: unknown) => {
              console.log(err)
            })
        },
      },
      setAdministratormodalData: {
        title: '设置管理员',
        form: {} as DeptClass,
        formItemList: [
          {
            type: 'slot',
            prop: 'adminId',
            placeholder: '请输入管理员姓名',
            label: '管理员姓名',
            optionList: [],
          },
          {
            type: 'subInput',
            prop: 'adminCode',
            placeholder: '输入姓名后自动获取管理员工号',
            label: '管理员工号',
            disabled: true,
          },
        ] as FormItemListType<Data>,
        rules: {
          adminId: [{ required: true, message: '管理员不可为空', trigger: 'blur' }],
        },
        visible: false,
        labelCol: { style: { width: '100px' } },
        width: '25%',
        ok: (formRef?: Ref) => {
          formRef?.value
            .validate()
            .then(() => {
              let api = UpdateDept

              api(pageData.setAdministratormodalData.form)
                .then(() => {
                  pageData.setAdministratormodalData.visible = false
                  initeList()
                  message.success('成功')
                })
                .catch((err) => {
                  message.error(err)
                })
            })
            .catch((err: unknown) => {
              console.log(err)
            })
        },
      },
    })

    if (store.state.regForm[route.name as string] !== undefined) {
      pageData.selectFrom = JSON.parse(JSON.stringify(store.state.regForm[route.name as string]))
    }
    //获取表格信息
    const initeList = () => {
      pageData.loading = true
      PageDept(pageData.selectFrom)
        .then((res) => {
          pageData.dataSource = []
          if (res.data) {
            Object.assign(pageData.dataSource, res.data)
          }
          pageData.selectFrom.currentPage = res.currentPage as number
          pageData.selectFrom.pageSize = res.pageSize
          pageData.selectFrom.dataCount = res.dataCount
          pageData.loading = false
        })
        .catch((err) => {
          pageData.loading = false
          message.error(err)
        })
    }
    //重置数据
    const selectProduct = () => {
      pageData.selectFrom.currentPage = 1
      pageData.selectFrom.pageSize = 10
      initeList()
    }
    //查询数据
    selectProduct()
    //传入table的页面配置
    const currentConfigure = computed(() => {
      let page = {
        pageSize: pageData.selectFrom.pageSize,
        currentPage: pageData.selectFrom.currentPage,
        total: pageData.selectFrom.dataCount,
        onChange(page: number, pageSize: number) {
          pageData.selectFrom.pageSize = pageSize
          pageData.selectFrom.currentPage = page
          initeList()
        },
        onShowSizeChange(current: number, size: number) {
          pageData.selectFrom.pageSize = size
          pageData.selectFrom.currentPage = current
          initeList()
        },
      }
      return page
    })

    //搜索
    const search = () => {
      selectProduct()
    }

    //重置
    const reset = () => {
      pageData.selectFrom.data = new DeptClass()
      selectProduct()
    }

    // 全选事件
    // const checkedChange = (checked: boolean) => {
    //   pageData.selectedRowKeys = checked
    //     ? (pageData.dataSource.map((item) => item.id) as string[]) || []
    //     : []
    // }

    // 跳转详情
    const goDetail = (record: DeptClass) => {
      // if (record.informationType === 'url') {
      //   window.open(record.url)
      // }
      console.log(record)
    }

    // 删除
    const deleteList = (id: string) => {
      const ok = () => {
        return new Promise(() => {
          DeleteDept(id)
            .then(() => {
              message.success('删除成功')
              initeList()
            })
            .catch((err) => {
              message.error(err)
            })
        })
      }
      const onCancel = () => {
        console.log('Cancel')
      }
      deleteOneModal(ok, onCancel)
    }

    //新增事件
    const addDept = () => {
      console.log('新增')
      pageData.modalData.title = '新增'
      pageData.modalData.form = {} as DeptClass
      pageData.modalData.visible = true
    }

    //修改事件
    const updateDept = (item: DeptClass) => {
      router.push(`/authorityManagement/departmentInformation/detail/${item.id}`)
    }

    const fetching = ref(false)
    const superiorDeptList: Ref<DeptClass[]> = ref([])
    // 远程搜索部门名称
    const superiorDeptSearch = debounce((val: string) => {
      getSuperiorDept(val, superiorDeptList, fetching)
    }, 300)

    const superiorDeptMemberList: Ref<PersonnelClass[]> = ref([])
    // 远程搜索人员名称
    const superiorDeptMemberSearch = debounce((val: string) => {
      getSuperiorDeptMember(val, superiorDeptMemberList, fetching)
    }, 300)
    const setAdmin = (item: DeptClass) => {
      pageData.setAdministratormodalData.form = JSON.parse(JSON.stringify(item))
      superiorDeptMemberList.value = [
        {
          name: item.adminName,
          id: item.adminId,
        } as PersonnelClass,
      ]
      pageData.setAdministratormodalData.visible = true
    }

    // 改变adminid事件
    const adminIdChange = (value: string, option: Data | Array<Data>) => {
      console.log(value, option)
      // pageData.setAdministratormodalData.form.adminCode = undefined
      pageData.setAdministratormodalData.form.adminCode = (option as Data).code as string
    }
    return {
      adminIdChange,
      superiorDeptMemberSearch,
      superiorDeptMemberList,
      fetching,
      superiorDeptList,
      setAdmin,
      superiorDeptSearch,
      addDept,
      updateDept,
      goDetail,
      ...toRefs(pageData),
      search,
      reset,
      buttonObj,
      currentConfigure,
      selectProduct,
      deleteList,
      updateColumns,
      // checkedChange,
    }
  },
})
