import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e2a46a3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_editButton = _resolveComponent("editButton")!
  const _component_yTable = _resolveComponent("yTable")!
  const _component_Drawer = _resolveComponent("Drawer")!
  const _component_listLayout = _resolveComponent("listLayout")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_modalForm = _resolveComponent("modalForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_listLayout, {
      num: _ctx.selectedRowKeys.length,
      onEmpty: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedRowKeys.length = 0))
    }, {
      selectList: _withCtx(() => [
        _createVNode(_component_a_form, {
          layout: "inline",
          model: _ctx.selectFrom.data,
          "label-col": { style: { width: '110px' } }
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formItemList, (item) => {
              return (_openBlock(), _createBlock(_component_a_form_item, {
                key: item.prop,
                label: item.label,
                name: item.prop
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    value: _ctx.selectFrom.data[item.prop],
                    "onUpdate:value": ($event: any) => ((_ctx.selectFrom.data[item.prop]) = $event),
                    formItemContent: item
                  }, null, 8, ["value", "onUpdate:value", "formItemContent"])
                ]),
                _: 2
              }, 1032, ["label", "name"]))
            }), 128)),
            _createVNode(_component_a_form_item, {
              class: "button",
              label: " ",
              colon: false
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _ctx.selectProduct
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("查询")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_a_button, { onClick: _ctx.reset }, {
                  default: _withCtx(() => [
                    _createTextVNode("重置")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      buttonList: _withCtx(() => [
        _createVNode(_component_editButton, {
          icon: _ctx.buttonObj['bcic-dept-create'],
          text: "新增",
          type: "primary",
          onClick: _ctx.addDept
        }, null, 8, ["icon", "onClick"]),
        _createElementVNode("span", {
          class: "iconfont icon-lieshezhi pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShowDrawer = true))
        })
      ]),
      table: _withCtx(() => [
        _createVNode(_component_yTable, {
          dataSource: _ctx.dataSource,
          columns: _ctx.columns,
          selectedRowKeys: _ctx.selectedRowKeys,
          "onUpdate:selectedRowKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedRowKeys) = $event)),
          currentConfigure: _ctx.currentConfigure,
          rowKey: 'id'
        }, {
          bodyCell: _withCtx(({ column, record }) => [
            (column.dataIndex === 'superiorDeptName')
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(record.superiorDeptName || '-'), 1))
              : (column.dataIndex === 'operation')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_ctx.buttonObj['bcic-dept-update'])
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: "edit",
                          onClick: ($event: any) => (_ctx.setAdmin(record))
                        }, "设置管理员", 8, _hoisted_3))
                      : _createCommentVNode("", true),
                    (_ctx.buttonObj['bcic-dept-info'])
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          class: "edit",
                          onClick: ($event: any) => (_ctx.updateDept(record))
                        }, "编辑", 8, _hoisted_4))
                      : _createCommentVNode("", true),
                    (_ctx.buttonObj['bcic-dept-delete'])
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 2,
                          class: "edit",
                          onClick: ($event: any) => (_ctx.deleteList(record.id))
                        }, "删除", 8, _hoisted_5))
                      : _createCommentVNode("", true)
                  ]))
                : (column.dataIndex === 'isFollow')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createElementVNode("i", {
                        class: "iconfont icon-xinhao",
                        style: _normalizeStyle({
                  'font-size': '10px',
                  color: record.isFollow === 1 ? '#13c2c2' : '#f96868',
                  'margin-right': '5px',
                })
                      }, null, 4),
                      _createTextVNode(" " + _toDisplayString(record.isFollow === 1 ? '已关注' : '未关注'), 1)
                    ], 64))
                  : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["dataSource", "columns", "selectedRowKeys", "currentConfigure"]),
        _createVNode(_component_Drawer, {
          visible: _ctx.isShowDrawer,
          "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isShowDrawer) = $event)),
          "origin-columns": _ctx.columns,
          "page-name": "departmentInformation",
          onUpdateColumns: _ctx.updateColumns
        }, null, 8, ["visible", "origin-columns", "onUpdateColumns"])
      ]),
      _: 1
    }, 8, ["num"]),
    _createVNode(_component_modalForm, {
      modalData: _ctx.modalData,
      visible: _ctx.modalData.visible,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modalData.visible) = $event)),
      onOnUpdateForm: _cache[6] || (_cache[6] = (val, prop) => {
        _ctx.modalData.form[prop] = val as undefined
      })
    }, {
      superiorDept: _withCtx(() => [
        _createVNode(_component_a_select, {
          value: _ctx.modalData.form.superiorDept,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modalData.form.superiorDept) = $event)),
          "show-search": "",
          placeholder: "请输入部门名称查询",
          "default-active-first-option": false,
          "show-arrow": false,
          "filter-option": false,
          "not-found-content": _ctx.fetching ? undefined : null,
          options: _ctx.superiorDeptList,
          "field-names": { label: 'deptName', value: 'id', options: 'children' },
          onSearch: _ctx.superiorDeptSearch
        }, null, 8, ["value", "not-found-content", "options", "onSearch"])
      ]),
      _: 1
    }, 8, ["modalData", "visible"]),
    _createVNode(_component_modalForm, {
      modalData: _ctx.setAdministratormodalData,
      visible: _ctx.setAdministratormodalData.visible,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.setAdministratormodalData.visible) = $event)),
      onOnUpdateForm: _cache[9] || (_cache[9] = (val, prop) => {
        _ctx.setAdministratormodalData.form[prop] = val as undefined
      })
    }, {
      adminId: _withCtx(() => [
        _createVNode(_component_a_select, {
          value: _ctx.setAdministratormodalData.form.adminId,
          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.setAdministratormodalData.form.adminId) = $event)),
          "show-search": "",
          placeholder: "请输入姓名查询",
          "default-active-first-option": false,
          "show-arrow": false,
          "filter-option": false,
          "not-found-content": _ctx.fetching ? undefined : null,
          options: _ctx.superiorDeptMemberList,
          "field-names": { label: 'name', value: 'id', options: 'children' },
          onChange: _ctx.adminIdChange,
          onSearch: _ctx.superiorDeptMemberSearch
        }, null, 8, ["value", "not-found-content", "options", "onChange", "onSearch"])
      ]),
      _: 1
    }, 8, ["modalData", "visible"])
  ]))
}